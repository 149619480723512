import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import ApiService from "../../services/ApiService"
import { Amplify } from "aws-amplify"
import { Authenticator } from "@aws-amplify/ui-react"
import awsconfig from "../../aws-exports"

import "@aws-amplify/ui-react/styles.css"
import "../../styles/global.scss"

Amplify.configure(awsconfig)

export default function SignUpPage({ params: { id } }) {
  
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    const link = await ApiService.getSignUpLink(id)

    if (!link?.enabled || link.expiryDate < new Date().toISOString()) {
      navigate("/classroom")
    } else{
      setLoaded(true);
    }
  })

  return (
    <>
      {loaded ? (
        <Authenticator
          className="classroom-login secret-register"
          initialState="signUp"
        >
          {() => <>{navigate("/classroom")}</>}
        </Authenticator>
      ) : (
        ""
      )}
    </>
  )
}
